import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'input', 'name' ];

  getFilename() {
    const fileInput = this.inputTarget;
    if (fileInput.files.length > 0) {
      const fileName = this.nameTarget;
      fileName.textContent = fileInput.files[0].name;
    }
  }
}
