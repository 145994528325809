import { Controller } from "stimulus"
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';

export default class extends Controller {
  static targets = ["content"];

  connect() {
    const keys = JSON.parse(this.data.get("keys"));
    const datasets = JSON.parse(this.data.get("datasets"));
    const colors = this.data.get("colors");
    const xAxesType = this.data.get("xaxestype");
    var xAxes = {
      grid: {
        display: false,
      }
    }

    if (xAxesType == 'time') {
      xAxes = {
        type: 'time',
        time: {
          unit: 'month'
        },
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          maxTicksLimit: 4,
        },
      }
    }
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: xAxes,
        y: {
          ticks: {
            maxTicksLimit: 4
          },
        }
      }
    }

    new Chart(this.contentTarget, {
      type: 'line',
      data: {
        labels: keys,
        datasets: [{
          label: '',
          data: datasets,
          backgroundColor: colors,
          borderColor: colors,
          fillColor: colors,
          strokeColor: colors,
        }]
      },
      options: options
    });
  }
}
