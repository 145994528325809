import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const choicesElement = document.querySelectorAll('.choicejs-country-select');

    choicesElement.forEach(elt => {
      elt.choices = new Choices(elt, {
        placeholderValue: 'Sélectionner un pays',
        searchPlaceholderValue: 'Chercher un pays',
        noResultsText: 'Aucun résultat',
        noChoicesText: 'Pas de pays',
        itemSelectText: '',
        removeItemButton: false,
        shouldSort: false,
        choices: [
          {
            label: 'Pays',
            id: 1,
            disabled: false,
            choices: [
              { value: "NE 227", label: "Niger (+227)", selected: true },
              { value: "BJ 229", label: "Benin (+229)" },
              { value: "FR 33", label: "France +33" },
              { value: "US 1", label: "États-Unis (+1)" },
            ],
          },
          {
            label: 'Autre pays',
            id: 2,
            disabled: false,
            choices: [
              { value: "AF 93", label: "Afghanistan +93" },
              { value: "AX 358", label: "Îles Åland +358" },
              { value: "AL 355", label: "Albanie +355" },
              { value: "DZ 213", label: "Algérie +213" },
              { value: "AS 1684", label: "Samoa américaines +1684" },
              { value: "AD 376", label: "Andorre +376" },
              { value: "AO 244", label: "Angola +244" },
              { value: "AI 1264", label: "Anguilla +1264" },
              { value: "AQ 672", label: "Antarctique +672" },
              { value: "AG 1268", label: "Antigua-et-Barbuda +1268" },
              { value: "AR 54", label: "Argentine +54" },
              { value: "AM 374", label: "Arménie +374" },
              { value: "AW 297", label: "Aruba +297" },
              { value: "AU 61", label: "Australie +61" },
              { value: "AT 43", label: "Autriche +43" },
              { value: "AZ 994", label: "Azerbaïdjan +994" },
              { value: "BS 1242", label: "Bahamas +1242" },
              { value: "BH 973", label: "Bahreïn +973" },
              { value: "BD 880", label: "Bangladesh +880" },
              { value: "BB 1246", label: "Barbade +1246" },
              { value: "BY 375", label: "Biélorussie +375" },
              { value: "BE 32", label: "Belgique +32" },
              { value: "BZ 501", label: "Belize +501" },
              { value: "BJ 229", label: "Bénin +229" },
              { value: "BM 1441", label: "Bermudes +1441" },
              { value: "BT 975", label: "Bhoutan +975" },
              { value: "BO 591", label: "Bolivie +591" },
              { value: "BQ 599", label: "Pays-Bas caribéens +599" },
              { value: "BA 387", label: "Bosnie-Herzégovine +387" },
              { value: "BW 267", label: "Botswana +267" },
              { value: "BV 55", label: "Île Bouvet +55" },
              { value: "BR 55", label: "Brésil +55" },
              { value: "IO 246", label: "Territoire britannique de l'océan Indien +246" },
              { value: "BN 673", label: "Brunei +673" },
              { value: "BG 359", label: "Bulgarie +359" },
              { value: "BF 226", label: "Burkina Faso +226" },
              { value: "BI 257", label: "Burundi +257" },
              { value: "KH 855", label: "Cambodge +855" },
              { value: "CM 237", label: "Cameroun +237" },
              { value: "CA 1", label: "Canada +1" },
              { value: "CV 238", label: "Cap-Vert +238" },
              { value: "KY 1345", label: "Îles Caïmans +1345" },
              { value: "CF 236", label: "République centrafricaine +236" },
              { value: "TD 235", label: "Tchad +235" },
              { value: "CL 56", label: "Chili +56" },
              { value: "CN 86", label: "Chine +86" },
              { value: "CX 61", label: "Île Christmas +61" },
              { value: "CC 672", label: "Îles Cocos +672" },
              { value: "CO 57", label: "Colombie +57" },
              { value: "KM 269", label: "Comores +269" },
              { value: "CG 242", label: "Congo-Brazzaville +242" },
              { value: "CD 242", label: "Congo-Kinshasa +242" },
              { value: "CK 682", label: "Îles Cook +682" },
              { value: "CR 506", label: "Costa Rica +506" },
              { value: "CI 225", label: "Côte d'Ivoire +225" },
              { value: "HR 385", label: "Croatie +385" },
              { value: "CU 53", label: "Cuba +53" },
              { value: "CW 599", label: "Curaçao +599" },
              { value: "CY 357", label: "Chypre +357" },
              { value: "CZ 420", label: "Tchéquie +420" },
              { value: "DK 45", label: "Danemark +45" },
              { value: "DJ 253", label: "Djibouti +253" },
              { value: "DM 1767", label: "Dominique +1767" },
              { value: "DO 1809", label: "République dominicaine +1809" },
              { value: "EC 593", label: "Équateur +593" },
              { value: "EG 20", label: "Égypte +20" },
              { value: "SV 503", label: "Salvador +503" },
              { value: "GQ 240", label: "Guinée équatoriale +240" },
              { value: "ER 291", label: "Érythrée +291" },
              { value: "EE 372", label: "Estonie +372" },
              { value: "ET 251", label: "Éthiopie +251" },
              { value: "FK 500", label: "Îles Malouines (Îles Falkland) +500" },
              { value: "FO 298", label: "Îles Féroé +298" },
              { value: "FJ 679", label: "Fidji +679" },
              { value: "FI 358", label: "Finlande +358" },
              { value: "GF 594", label: "Guyane française +594" },
              { value: "PF 689", label: "Polynésie française +689" },
              { value: "TF 262", label: "Terres australes françaises +262" },
              { value: "GA 241", label: "Gabon +241" },
              { value: "GM 220", label: "Gambie +220" },
              { value: "GE 995", label: "Géorgie +995" },
              { value: "DE 49", label: "Allemagne +49" },
              { value: "GH 233", label: "Ghana +233" },
              { value: "GI 350", label: "Gibraltar +350" },
              { value: "GR 30", label: "Grèce +30" },
              { value: "GL 299", label: "Groenland +299" },
              { value: "GD 1473", label: "Grenade +1473" },
              { value: "GP 590", label: "Guadeloupe +590" },
              { value: "GU 1671", label: "Guam +1671" },
              { value: "GT 502", label: "Guatemala +502" },
              { value: "GG 44", label: "Guernesey +44" },
              { value: "GN 224", label: "Guinée +224" },
              { value: "GW 245", label: "Guinée-Bissau +245" },
              { value: "GY 592", label: "Guyana +592" },
              { value: "HT 509", label: "Haïti +509" },
              { value: "HM 0", label: "Îles Heard-et-MacDonald +0" },
              { value: "VA 39", label: "État de la Cité du Vatican +39" },
              { value: "HN 504", label: "Honduras +504" },
              { value: "HK 852", label: "Hong Kong +852" },
              { value: "HU 36", label: "Hongrie +36" },
              { value: "IS 354", label: "Islande +354" },
              { value: "IN 91", label: "Inde +91" },
              { value: "ID 62", label: "Indonésie +62" },
              { value: "IR 98", label: "Iran +98" },
              { value: "IQ 964", label: "Irak +964" },
              { value: "IE 353", label: "Irlande +353" },
              { value: "IM 44", label: "Île de Man +44" },
              { value: "IL 972", label: "Israël +972" },
              { value: "IT 39", label: "Italie +39" },
              { value: "JM 1876", label: "Jamaïque +1876" },
              { value: "JP 81", label: "Japon +81" },
              { value: "JE 44", label: "Jersey +44" },
              { value: "JO 962", label: "Jordanie +962" },
              { value: "KZ 7", label: "Kazakhstan +7" },
              { value: "KE 254", label: "Kenya +254" },
              { value: "KI 686", label: "Kiribati +686" },
              { value: "KP 850", label: "Corée du Nord +850" },
              { value: "KR 82", label: "Corée du Sud +82" },
              { value: "XK 381", label: "Kosovo +381" },
              { value: "KW 965", label: "Koweït +965" },
              { value: "KG 996", label: "Kirghizstan +996" },
              { value: "LA 856", label: "Laos +856" },
              { value: "LV 371", label: "Lettonie +371" },
              { value: "LB 961", label: "Liban +961" },
              { value: "LS 266", label: "Lesotho +266" },
              { value: "LR 231", label: "Liberia +231" },
              { value: "LY 218", label: "Libye +218" },
              { value: "LI 423", label: "Liechtenstein +423" },
              { value: "LT 370", label: "Lituanie +370" },
              { value: "LU 352", label: "Luxembourg +352" },
              { value: "MO 853", label: "Macao +853" },
              { value: "MK 389", label: "Macédoine du Nord +389" },
              { value: "MG 261", label: "Madagascar +261" },
              { value: "MW 265", label: "Malawi +265" },
              { value: "MY 60", label: "Malaisie +60" },
              { value: "MV 960", label: "Maldives +960" },
              { value: "ML 223", label: "Mali +223" },
              { value: "MT 356", label: "Malte +356" },
              { value: "MH 692", label: "Îles Marshall +692" },
              { value: "MQ 596", label: "Martinique +596" },
              { value: "MR 222", label: "Mauritanie +222" },
              { value: "MU 230", label: "Maurice +230" },
              { value: "YT 262", label: "Mayotte +262" },
              { value: "MX 52", label: "Mexique +52" },
              { value: "FM 691", label: "Micronésie +691" },
              { value: "MD 373", label: "Moldavie +373" },
              { value: "MC 377", label: "Monaco +377" },
              { value: "MN 976", label: "Mongolie +976" },
              { value: "ME 382", label: "Monténégro +382" },
              { value: "MS 1664", label: "Montserrat +1664" },
              { value: "MA 212", label: "Maroc +212" },
              { value: "MZ 258", label: "Mozambique +258" },
              { value: "MM 95", label: "Myanmar (Birmanie) +95" },
              { value: "NA 264", label: "Namibie +264" },
              { value: "NR 674", label: "Nauru +674" },
              { value: "NP 977", label: "Népal +977" },
              { value: "NL 31", label: "Pays-Bas +31" },
              { value: "AN 599", label: "Curaçao +599" },
              { value: "NC 687", label: "Nouvelle-Calédonie +687" },
              { value: "NZ 64", label: "Nouvelle-Zélande +64" },
              { value: "NI 505", label: "Nicaragua +505" },
              { value: "NE 227", label: "Niger +227" },
              { value: "NG 234", label: "Nigeria +234" },
              { value: "NU 683", label: "Niue +683" },
              { value: "NF 672", label: "Île Norfolk +672" },
              { value: "MP 1670", label: "Îles Mariannes du Nord +1670" },
              { value: "NO 47", label: "Norvège +47" },
              { value: "OM 968", label: "Oman +968" },
              { value: "PK 92", label: "Pakistan +92" },
              { value: "PW 680", label: "Palaos +680" },
              { value: "PS 970", label: "Palestine +970" },
              { value: "PA 507", label: "Panama +507" },
              { value: "PG 675", label: "Papouasie-Nouvelle-Guinée +675" },
              { value: "PY 595", label: "Paraguay +595" },
              { value: "PE 51", label: "Pérou +51" },
              { value: "PH 63", label: "Philippines +63" },
              { value: "PN 64", label: "Îles Pitcairn +64" },
              { value: "PL 48", label: "Pologne +48" },
              { value: "PT 351", label: "Portugal +351" },
              { value: "PR 1787", label: "Porto Rico +1787" },
              { value: "QA 974", label: "Qatar +974" },
              { value: "RE 262", label: "La Réunion +262" },
              { value: "RO 40", label: "Roumanie +40" },
              { value: "RU 70", label: "Russie +70" },
              { value: "RW 250", label: "Rwanda +250" },
              { value: "BL 590", label: "Saint-Barthélemy +590" },
              { value: "SH 290", label: "Sainte-Hélène +290" },
              { value: "KN 1869", label: "Saint-Christophe-et-Niévès +1869" },
              { value: "LC 1758", label: "Sainte-Lucie +1758" },
              { value: "MF 590", label: "Saint-Martin +590" },
              { value: "PM 508", label: "Saint-Pierre-et-Miquelon +508" },
              { value: "VC 1784", label: "Saint-Vincent-et-les Grenadines +1784" },
              { value: "WS 684", label: "Samoa +684" },
              { value: "SM 378", label: "Saint-Marin +378" },
              { value: "ST 239", label: "Sao Tomé-et-Principe +239" },
              { value: "SA 966", label: "Arabie saoudite +966" },
              { value: "SN 221", label: "Sénégal +221" },
              { value: "RS 381", label: "Serbie +381" },
              { value: "CS 381", label: "Serbie +381" },
              { value: "SC 248", label: "Seychelles +248" },
              { value: "SL 232", label: "Sierra Leone +232" },
              { value: "SG 65", label: "Singapour +65" },
              { value: "SX 1", label: "Saint-Martin (partie néerlandaise) +1" },
              { value: "SK 421", label: "Slovaquie +421" },
              { value: "SI 386", label: "Slovénie +386" },
              { value: "SB 677", label: "Îles Salomon +677" },
              { value: "SO 252", label: "Somalie +252" },
              { value: "ZA 27", label: "Afrique du Sud +27" },
              { value: "GS 500", label: "Géorgie du Sud-et-les Îles Sandwich du Sud +500" },
              { value: "SS 211", label: "Soudan du Sud +211" },
              { value: "ES 34", label: "Espagne +34" },
              { value: "LK 94", label: "Sri Lanka +94" },
              { value: "SD 249", label: "Soudan +249" },
              { value: "SR 597", label: "Suriname +597" },
              { value: "SJ 47", label: "Svalbard et Jan Mayen +47" },
              { value: "SZ 268", label: "Eswatini +268" },
              { value: "SE 46", label: "Suède +46" },
              { value: "CH 41", label: "Suisse +41" },
              { value: "SY 963", label: "Syrie +963" },
              { value: "TW 886", label: "Taïwan +886" },
              { value: "TJ 992", label: "Tadjikistan +992" },
              { value: "TZ 255", label: "Tanzanie +255" },
              { value: "TH 66", label: "Thaïlande +66" },
              { value: "TL 670", label: "Timor oriental +670" },
              { value: "TG 228", label: "Togo +228" },
              { value: "TK 690", label: "Tokelau +690" },
              { value: "TO 676", label: "Tonga +676" },
              { value: "TT 1868", label: "Trinité-et-Tobago +1868" },
              { value: "TN 216", label: "Tunisie +216" },
              { value: "TR 90", label: "Turquie +90" },
              { value: "TM 7370", label: "Turkménistan +7370" },
              { value: "TC 1649", label: "Îles Turques-et-Caïques +1649" },
              { value: "TV 688", label: "Tuvalu +688" },
              { value: "UG 256", label: "Ouganda +256" },
              { value: "UA 380", label: "Ukraine +380" },
              { value: "AE 971", label: "Émirats arabes unis +971" },
              { value: "GB 44", label: "Royaume-Uni +44" },
              { value: "UM 1", label: "Îles mineures éloignées des États-Unis +1" },
              { value: "UY 598", label: "Uruguay +598" },
              { value: "UZ 998", label: "Ouzbékistan +998" },
              { value: "VU 678", label: "Vanuatu +678" },
              { value: "VE 58", label: "Venezuela +58" },
              { value: "VN 84", label: "Viêt Nam +84" },
              { value: "VG 1284", label: "Îles Vierges britanniques +1284" },
              { value: "VI 1340", label: "Îles Vierges des États-Unis +1340" },
              { value: "WF 681", label: "Wallis-et-Futuna +681" },
              { value: "EH 212", label: "Sahara occidental +212" },
              { value: "YE 967", label: "Yémen +967" },
              { value: "ZM 260", label: "Zambie +260" },
              { value: "ZW 263", label: "Zimbabwe +263" },
            ],
          },
        ],
      })
    });
  }
}

