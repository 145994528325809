const showElement = (element) => {
  element.classList.remove("is-hidden");
};

const hideElement = (element) => {
  element.classList.add("is-hidden");
};

const toggleElement = (element) => {
  element.classList.toggle("is-hidden");
};

const addClassToAll = (cssClass, elements) => {
  elements.forEach((element) => {
    element.classList.add(cssClass);
  });
};

const removeClassFromAll = (cssClass, elements) => {
  elements.forEach((element) => {
    element.classList.remove(cssClass);
  });
};

export { showElement, hideElement, toggleElement, addClassToAll, removeClassFromAll };
