import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['country', 'msisdn', 'environment', 'msisdnError', 'amount', 'amountError']

  connect() {
    this.msisdnTarget.addEventListener("change", this.checkMsisdnChange.bind(this));
    this.amountTarget.addEventListener("change", this.validAmount.bind(this));
    
  }

  checkMsisdnChange(){
    const environment = this.environmentTarget.value
    if (environment == "sandbox"){
      if (!(this.isSandboxMsisdn())){
        this._hideSubmitButton()
        this.msisdnErrorTarget.classList.remove('is-hidden')
      }else{
        this.displaySubmitButton()
        this.msisdnErrorTarget.classList.add('is-hidden')
      }
    }

    if (environment == "live"){
      if(!this.isLiveMsisdn()){
        this._hideSubmitButton()
        this.msisdnErrorTarget.classList.remove('is-hidden')
      }else{
        this.displaySubmitButton()
        this.msisdnErrorTarget.classList.add('is-hidden')
      }
    }
  }

  validAmount(){
    if(this.amountTarget.value < 60 ){
      this._hideSubmitButton();
      this.amountErrorTarget.classList.remove('is-hidden')
    }else{
      this.displaySubmitButton();
      this.amountErrorTarget.classList.add('is-hidden')
    }
  }

  displaySubmitButton(){
    document.querySelector(".submit-button").classList.remove('is-hidden')
  }

  _hideSubmitButton(){
    document.querySelector(".submit-button").classList.add('is-hidden')
  }

  isSandboxMsisdn(){
    const ALLOWED_MSISDN = ["40410000000", "40410000001"]
    return ALLOWED_MSISDN.includes(this.msisdnTarget.value) 
  }

  isLiveMsisdn(){
    const country = this.countryTarget.value
    if (country == 'BJ'){
      const MTN_BJ_MSISDN = /(97|96|90|66|67|61|55|51)[0-9]{6}/
      const MOOV_BJ_MSISDN = /(95|94|64|65|60|53)[0-9]{6}/
      return this.msisdnTarget.value.match(MTN_BJ_MSISDN) || this.msisdnTarget.value.match(MOOV_BJ_MSISDN) ;
    }

    if (country == 'NE'){
      const MOOV_NE_MSISDN = /(96|97|98|99|87|88)[0-9]{6}/
      const AIRTEL_NE_MSISDN = /(70|74|80|81|82|84|85|90|86|91|92|94|95|89|77)[0-9]{6}/
      return this.msisdnTarget.value.match(MOOV_NE_MSISDN) || this.msisdnTarget.value.match(AIRTEL_NE_MSISDN);
    }
  }

}