import { Controller } from "stimulus"

const BJ = "229";
const NE = "227";

export default class extends Controller {
  static targets = [ "step", "formStep", "pendingMobileStep", "pendingAlizzaStep", "publicReference", "cartPendingStep",
                     "minPrice", "maxPrice", "price", "amount", 'total', 'phone', 'name', 'country', 'productId', 
                     'currentResponse', 'loadingStep', 'userPhone', 'cardPrice', 'cardPhone', 'cardCountry' ]

  connect() {
    this.total = 0;
    this.country = ''
    this.paymentOption = document.querySelector('input[name="payment-option"]:checked').value
    this.contryChanged();
    document.addEventListener("payment:succeed", this.updatePayment.bind(this));
  }

  contryChanged(e){
    // const price = parseInt(this.priceTarget.value);
    // this._amountValue(price);
  }

  paymentOptionChanged(e){
    this.paymentOption = document.querySelector('input[name="payment-option"]:checked').value
  }

  priceInputed(e) {
    this._insertPrice('mobile');
  }

  cardPriceInputed (e) {
    this._insertPrice('card');
  }

  resquestPayment(e) {
    if (!this.priceTarget.value || !this.phoneTarget.value || !this.nameTarget.value) {
      document.querySelector(".name_error").classList.remove("is-hidden");
      document.querySelector(".amount_error").classList.remove("is-hidden");
      document.querySelector(".phone_error").classList.remove("is-hidden");
      return;
    }
    fetch(`/products/request_payment?product_id=${this.productIdTarget.value}&amount=${this.priceTarget.value}&name=${this.nameTarget.value}&phone=${this.phoneTarget.value}&country=${this.countryTarget.value}&payment_option=${this.paymentOption}`)
    .then(response => response.json())
    .then(data => {
      document.querySelector(".payment-reference").value = data.reference;
      if (data.hasOwnProperty('errors')){
        document.querySelector(".error-system").classList.remove("is-hidden")
      }
      else {
        this._displayMobileOrAlizza(data.public_reference);
        document.querySelector(".error-system").classList.add("is-hidden")
      }
    })
    .catch( err => {
      console.log(err);
    })
  }

  requestPaymentPage(e) {
    var errorSystem = document.querySelector(".error-card-system");
    if (!this.cardPriceTarget.value || !this.cardPhoneTarget.value) {
      document.querySelector(".card_amount_error").classList.remove("is-hidden");
      document.querySelector(".card_phone_error").classList.remove("is-hidden");
      return;
    }
    this._displayStep("loadingStep");
    fetch(`/products/request_orabank_payment_page?product_id=${this.productIdTarget.value}&amount=${this.cardPriceTarget.value}&phone=${this.cardPhoneTarget.value}&country=${this.cardCountryTarget.value}&payment_option=${this.paymentOption}`)
    .then(response => response.json())
    .then(data => {
      this._displayStep("formStep");
      document.querySelector(".payment-reference").value = data.reference;
      document.querySelector("#page-url-input").value = data.page_url;
      document.querySelector("#page-card-button").classList.remove('is-hidden')
      // this._openPaymentPage(data.page_url);

      if (data.hasOwnProperty('errors')) {
        errorSystem.classList.remove("is-hidden")
      } else {
        errorSystem.classList.add("is-hidden")
      }
    })
    .catch( err => {
      this._displayStep("formStep");
      errorSystem.classList.remove("is-hidden")
      errorSystem.textContent = "Erreur système veuillez recommencer ou choisir un autre moyen de paiement"
      console.log(err);
    })
  }

  updatePayment(e) {
  
    if (document.querySelector(".payment-reference").value != e.detail.reference) { return; }
  
    const status = e.detail.status;
  
    if (status == 'succeeded') {
      document.querySelector('#payment_success').setAttribute("id", `payment_success_${e.detail.reference}`)
      this._displayStep("currentResponse");
    }
  
    if (status == 'failed') {
      document.querySelector('#payment_failed').setAttribute("id", `payment_failed_${e.detail.reference}`)
      this._displayStep("currentResponse");
    }
  }

  _displayMobileOrAlizza(value){
    if(this.paymentOption == 'mobile'){
      this._displayStep("pendingMobileStep");
    }

    if(this.paymentOption == 'alizza'){
      this._displayStep("pendingAlizzaStep");
      this.publicReferenceTarget.textContent = value;
    }
  }

  _openPaymentPage(page_url) {
    var url = document.querySelector('#ipay-page-url').value
    var page_url = document.querySelector('#page-url-input').value
    var payment_reference = document.querySelector(".payment-reference").value
    window.open(`${url}?payment_reference=${payment_reference}&page_url=${page_url}`, '_blank')
    this._displayStep("cartPendingStep");
  }

  closeWindow() {
    window.open('','_parent',''); 
    window.close();
  }

  _insertPrice(gateway) {
    const minPrice = parseInt(this.minPriceTarget.value);
    const maxPrice = parseInt(this.maxPriceTarget.value);
    let element;
    let price;
    if (gateway == 'mobile') {
      element = document.querySelector(".price_error");
      price = parseInt(this.priceTarget.value);
    } else {
      element = document.querySelector(".card_price_error");
      price = parseInt(this.cardPriceTarget.value);
    }
    if ((minPrice > price) || (maxPrice < price)) {
      this._displayPriceError(element, minPrice, maxPrice);
      // document.querySelector(".amount").textContent = "0";
      document.querySelector(".payment-button").disabled = true;
    } else {
      // document.querySelector(".amount").textContent = `${price}`;
      // this._amountValue(price);
      this._hidePriceError(element);
      document.querySelector(".payment-button").disabled = false;
    }
  }

  _displayPriceError(element, minPrice, maxPrice){
    element.textContent = `Le montant doit être compris entre: [${minPrice},${maxPrice}]`
  }

  _hidePriceError(element){
    element.textContent = ""
  }

  _amountValue(price){
    this.total = price;
    if(this.countryTarget.value === BJ){
      this.country = 'BJ';
    }
    if(this.countryTarget.value === NE) {
      this.country = 'NE';
    }
    if(this.total > 0) this.totalTarget.textContent = `${parseInt(this.total)}`
    if(this.total == 0) this.totalTarget.textContent = `0`
  }

  _displayStep(step) {
    this.stepTargets.forEach(elt => elt.classList.add('is-hidden'));
    this[`${step}Target`].classList.remove('is-hidden');
  }

  _displayLoader() {
    this.loaderTarget.classList.remove('is-hidden');
  }

  _hideLoader() {
    this.loaderTarget.classList.add('is-hidden');
  }

  _setpageUrl(page_url) {
    let mediaDesktop = window.matchMedia('(min-width: 1024px)');
    var pageUrl = document.querySelector("#page-url");
    var pageUrlButton = document.querySelector("#page-url-button");
    pageUrl.src = page_url;
    if (mediaDesktop.matches) {
      pageUrl.classList.remove("is-hidden");
    } else {
      pageUrlButton.classList.remove("is-hidden");
    }
  }
}
