import consumer from "./consumer"
import CableReady from 'cable_ready'

consumer.subscriptions.create({ channel: "PaymentsChannel", payer: "stream" }, {
  received(data) {
    if (data.operations) {
      const event = new CustomEvent('payment:succeed', { bubbles: true, detail: { status: data.operations[0].status, reference: data.operations[0].reference, otherData: data.operations[0].otherData }});
      document.dispatchEvent(event);
      if (data.cableReady) CableReady.perform(data.operations)
    }
  }
});