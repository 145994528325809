import { Controller } from "stimulus"
import Chart from 'chart.js/auto';

export default class extends Controller {
  static targets = ["content"];

  connect() {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: 'left',
          align: 'center',
          labels: {
            fontColor: '#666666',
            padding: 8,
            position: 'left',
            fontSize: 12
          }
        }
      }
    }
    const keys = JSON.parse(this.data.get("keys"));
    const datasets = JSON.parse(this.data.get("datasets"));
    const colors = JSON.parse(this.data.get("colors"));

    new Chart(this.contentTarget, {
      type: 'doughnut',
      data: {
        labels: keys,
        datasets: [{
          label: '',
          data: datasets,
          backgroundColor: colors,
          hoverOffset: 8
        }]
      },
      options: options
    });
  }
}
