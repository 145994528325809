import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button"];
  static values = {
    parentDomaine: String,
  }

  connect () {
    if (this.parentDomaineValue != '') this.buttonTarget.classList.remove("is-hidden");
  }

  onCloseIpayCheckout() {
    window.parent.postMessage({type: 'closeModal'}, '*')
  }
}
