import { Controller } from "stimulus"

const ACCORDION_CLASS = '.nav-accordion';

export default class extends Controller {
  static targets = [ "content", 'listSandbox' ];

  toggle(e) {
    e.preventDefault();
    const element = this.contentTarget;
    element.classList.toggle('is-active');
  }

  findElement(e) {
    return e.target.closest(ACCORDION_CLASS);
  }

  sandbox(e){
    document.querySelector('.list-sandbox').classList.remove('is-hidden')
  }

  live(e){
    document.querySelector('.list-sandbox').classList.add("is-hidden")
  }

}
