import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['amount', 'payoutPeriod', 'payoutType', 'payoutMethod', 'mobile', 'card']

  connect() {
    this.payoutTypeTarget.addEventListener("change", this.checkPayoutTypeChange.bind(this));
    this.payoutMethodTarget.addEventListener("change", this.checkPayoutMethodChange.bind(this));
  }

  checkPayoutTypeChange(){
    if (this.payoutTypeTarget.value == 'periodic'){
      this.payoutPeriodTarget.classList.remove('is-hidden');
      this.amountTarget.classList.add('is-hidden');
    } else {
      this.payoutPeriodTarget.classList.add('is-hidden');
      this.amountTarget.classList.remove('is-hidden');
    }
  }

  checkPayoutMethodChange(){
    if (this.payoutMethodTarget.value == 'mobile'){
      this.mobileTarget.classList.remove('is-hidden');
      this.cardTarget.classList.add('is-hidden');
    } else {
      this.mobileTarget.classList.add('is-hidden');
      this.cardTarget.classList.remove('is-hidden');
    }
  }

}
