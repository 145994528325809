import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['identityCard', 'passeportLabel', 'idCardLabel']

  connect() {
    this.identityCardTarget.addEventListener("change", this.checkIDTypeChange.bind(this));
  }

  checkIDTypeChange(){
    if (this.identityCardTarget.value == 'passeport'){
      this.idCardLabelTarget.classList.add('is-hidden');
      this.passeportLabelTarget.classList.remove('is-hidden');
    } else {
      this.idCardLabelTarget.classList.remove('is-hidden');
      this.passeportLabelTarget.classList.add('is-hidden');
    }
  }

}
