import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['payoutMethod', 'mobile', 'card']

  connect() {
    this.payoutMethodTarget.addEventListener("change", this.checkPayoutMethodChange.bind(this));
  }

  checkPayoutMethodChange(){
    if (this.payoutMethodTarget.value == 'mobile'){
      this.mobileTarget.classList.remove('is-hidden');
      this.cardTarget.classList.add('is-hidden');
    } else {
      this.mobileTarget.classList.add('is-hidden');
      this.cardTarget.classList.remove('is-hidden');
    }
  }

}
