import { Controller } from "stimulus"
import Chart from 'chart.js/auto';

export default class extends Controller {
  static targets = ["content"];

  connect() {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'left',
          align: 'center',
          labels: {
            fontColor: '#666666',
            padding: 8,
            position: 'left',
            fontSize: 12
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
          }
        },
        y: {
          ticks: {
            maxTicksLimit: 4
          },
        }
      }
    }
    const keys = JSON.parse(this.data.get("keys"));
    const datasets = JSON.parse(this.data.get("datasets"));
    const color = this.data.get("colors");

    new Chart(this.contentTarget, {
      type: 'bar',
      data: {
        labels: keys,
        datasets: [{
          label: '',
          data: datasets,
          backgroundColor: color,
        }]
      },
      options: options
    });
  }
}
