import { Controller } from "stimulus"
import { showElement, hideElement } from "controllers/utilities/display.js"

const FIXED_PRICE = "fixed_price";
const RANGE_PRICE = "range_price";
const SHORT_PAGE = "short_page";
const LONG_PAGE = "long_page";

export default class extends Controller {
  static targets = [ "fixedPrice", "rangePrice", "shortPage", "longPage", "priceOption", "template" ]

  connect() {
    this._updatePriceOptions();
    this._updateTemplate();
  }

  templateChanged(e) {
    this._updateTemplate();
  }

  priceOptionChanged(e) {
    this._updatePriceOptions();
  }

  _updatePriceOptions() {
    const value = this.priceOptionTarget.value;
    if (value === FIXED_PRICE) this._showFixedPriceFields();
    if (value === RANGE_PRICE) this._showRangePriceFields();
  }

  _updateTemplate() {
    const value = this.templateTarget.value;
    if (value === SHORT_PAGE) this._showShortPagePreview();
    if (value === LONG_PAGE) this._showLongPagePreview();
  }

  _showFixedPriceFields() {
    showElement(this.fixedPriceTarget);
    hideElement(this.rangePriceTarget);
  }

  _showRangePriceFields() {
    showElement(this.rangePriceTarget);
    hideElement(this.fixedPriceTarget);
  }

  _showShortPagePreview() {
    showElement(this.shortPageTarget);
    hideElement(this.longPageTarget);
  }

  _showLongPagePreview() {
    showElement(this.longPageTarget);
    hideElement(this.shortPageTarget);
  }
}
