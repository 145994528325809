import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["lessText", "moreButton", "moreText"];

  toggle () {
    this.moreTextTarget.classList.toggle('is-hidden');
    
    if (this.moreTextTarget.classList.contains('is-hidden')) {
      this.lessTextTarget.classList.remove("is-hidden");
      this.moreButtonTarget.innerHTML = "Lire plus";
    } else {
      this.moreButtonTarget.innerHTML = "Lire moins";
      this.lessTextTarget.classList.add("is-hidden");
    }
  }
}
